<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Prelab Quiz - Measurements and Significant Figures</h2>

      <p class="mb-2">
        a) How many <b>decimal places</b> should we have when using a micrometer to measure the
        diameter of a penny?
      </p>

      <p>
        <v-select
          v-model="inputs.input1"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select Decimal Places"
          class="mb-0 mt-0 ml-4"
          style="width: 200px"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-n3">
        b) Where do we measure the meniscus of the water in a beaker, buret, or graduated cylinder?
      </p>

      <v-radio-group v-model="inputs.input2" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options2"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">c) What tool do we use to measure mass?</p>

      <v-radio-group v-model="inputs.input3" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options3"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">d) What type of water will we be using in this lab?</p>

      <v-radio-group v-model="inputs.input4" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options4"
          :key="'pt-4-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-1">e) What items will be measured in this lab? Select all that apply.</p>

      <p v-for="option in options5" :key="'pt-5-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input5" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-1 mt-5">
        f) What do we need to discuss in our error analysis? Select all that apply.
      </p>

      <p v-for="option in options6" :key="'pt-6-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input6" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-n3 mt-5">g) What three properties are we measuring in this lab?</p>

      <v-radio-group v-model="inputs.input7" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options7"
          :key="'pt-7-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'HawkeyePrelabQuiz1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: [],
        input6: [],
        input7: null,
      },
      items: [
        {text: '0', value: '0'},
        {text: '1', value: '1'},
        {text: '2', value: '2'},
        {text: '3', value: '3'},
        {text: '4', value: '4'},
        {text: '5', value: '5'},
        {text: '6', value: '6'},
      ],
      options2: [
        {text: '$\\text{At the top of the meniscus}$', value: 'a'},
        {text: '$\\text{In the middle of the meniscus}$', value: 'b'},
        {text: '$\\text{At the bottom of the meniscus}$', value: 'c'},
        {text: '$\\text{Wherever you want}$', value: 'd'},
      ],
      options3: [
        {text: '$\\text{A beaker}$', value: 'a'},
        {text: '$\\text{The benchtop}$', value: 'b'},
        {text: '$\\text{A meter stick}$', value: 'c'},
        {text: '$\\text{A balance}$', value: 'd'},
        {text: '$\\text{Our hands}$', value: 'e'},
      ],
      options4: [
        {text: '$\\text{We are using acid, not water}$', value: 'a'},
        {text: '$\\text{Distilled water}$', value: 'b'},
        {text: '$\\text{Tap water}$', value: 'c'},
        {text: '$\\text{Deionized water}$', value: 'd'},
        {text: '$\\text{Vitamin water}$', value: 'e'},
      ],
      options5: [
        {text: '$\\text{Volume of water in a graduated cylinder}$', value: 'a'},
        {text: '$\\text{Mass of a graduated cylinder}$', value: 'b'},
        {text: '$\\text{Mass and volume of ethanol}$', value: 'c'},
        {text: '$\\text{Mass of a short metal cylinder}$', value: 'd'},
        {text: '$\\text{Height of a short metal cylinder}$', value: 'e'},
      ],
      options6: [
        {text: '$\\text{The percent error from calculations}$', value: 'a'},
        {text: '$\\text{The \\%RSD calculated from the pipette readings}$', value: 'b'},
        {text: '$\\text{The percent yield}$', value: 'c'},
        {text: '$\\text{The R}^2\\text{ value from a graph we make}$', value: 'd'},
      ],
      options7: [
        {text: '$\\text{Length, volume, and density}$', value: 'a'},
        {text: '$\\text{Length, mass, and density}$', value: 'b'},
        {text: '$\\text{Mass, area, density}$', value: 'c'},
        {text: '$\\text{Length, mass, and volume}$', value: 'd'},
      ],
    };
  },
};
</script>
<style scoped>
.v-input--checkbox {
  display: inline-block !important;
}
</style>
